// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDKVhdPPGGoFD0dg6SMoOLYei6FIrrK8Zc",
    authDomain: "intercom-46613.firebaseapp.com",
    projectId: "intercom-46613",
    storageBucket: "intercom-46613.appspot.com",
    messagingSenderId: "189459794650",
    appId: "1:189459794650:web:b4a94155de318a9c05b3e3",
    measurementId: "G-2SQ4RWJESR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

// Initialize Firestore and get a reference to the service
const db = getFirestore(app);

export { auth, db };
