// AudioCall.js
import React, { useContext, useCallback, useRef, useState, useEffect } from 'react';
import { GlobalContext } from '../utils/GlobalContext';
import { auth, db } from "../firebase";
import { doc, updateDoc, onSnapshot, getDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { ConnectorContext } from '../App';
import { getDocRefByEmail, getIncomingConnectionRefByEmail } from "../utils/userUtils";
import './AudioCall.css'; // For custom styling

const AudioCall = () => {
    const { user, peers, setActiveAudioCall, currentPeers, startScreenShare, contacts, selectedContact, setSelectedContact, requester, setRequester, setCurrentComponent, createPeerConnection, fullScreenVideoRef, floatingVideoRef, localStream, remoteStream, activeComponent, endAudioCall, localAudioRef, remoteAudioRef, addVideo, setAddVideo, addVideoRequest, setAddVideoRequest, addMediaStream } = useContext(ConnectorContext);
    
    const [isMuted, setIsMuted] = useState(false);
    const [isSpeakerOn, setIsSpeakerOn] = useState(false);

    useEffect(() => {
        if (localAudioRef.current) {
            localAudioRef.current.srcObject = localStream;
        }
        if (remoteAudioRef.current) {
            remoteAudioRef.current.srcObject = remoteStream;
        }
    }, [localStream, remoteStream]);

    const toggleMute = () => {
        const audioTrack = localStream.getAudioTracks()[0];
        if (audioTrack) {
            audioTrack.enabled = !audioTrack.enabled;
            setIsMuted(!audioTrack.enabled);
        }
    };

    const toggleSpeaker = () => {
        if (remoteAudioRef.current) {
            // Toggles the "output device" (speaker/earpiece). Works on mobile (WebRTC or Cordova/Capacitor apps).
            setIsSpeakerOn((prev) => !prev);
            remoteAudioRef.current.setSinkId(isSpeakerOn ? "default" : "speaker");
        }
    };

    const handleEndCall = () => {
        let activeCaller = null

        if (selectedContact) {
            activeCaller = selectedContact.email
        } else if (requester) {
            activeCaller = requester
        }
        currentPeers.current[activeCaller].signalDataChannel.send(JSON.stringify({type: "endCall", contact: user.email}));
        endAudioCall(activeCaller)
        setActiveAudioCall(false)
    };

    const startVideoCall = () => {
        console.log('onStartVideoCall'); // This will switch the parent component to video call mode
        let activeCaller = null

        if (selectedContact) {
            activeCaller = selectedContact.email
        } else if (requester) {
            activeCaller = requester
        }
        //activeComponent.current = 'dialing'
        //setCurrentComponent('dialing')
        setAddVideoRequest(true)
        currentPeers.current[activeCaller].signalDataChannel.send(JSON.stringify({type: "addVideo", contact: user.email}));

    };

    const onConfirm = async() => {
        console.log('onConfirm');
        let activeCaller = null

        if (selectedContact) {
            activeCaller = selectedContact.email
        } else if (requester) {
            activeCaller = requester
        }
        //activeComponent.current = 'dialing'
        //setCurrentComponent('dialing')
        setAddVideo(false)
        currentPeers.current[activeCaller].signalDataChannel.send(JSON.stringify({type: "acceptedAddVideo", contact: user.email}));
        activeComponent.current = 'videoCall'
        setCurrentComponent('videoCall')
        await addMediaStream(activeCaller)
    }

    const onCancel = () => {
        console.log('onCancel');
        let activeCaller = null

        if (selectedContact) {
            activeCaller = selectedContact.email
        } else if (requester) {
            activeCaller = requester
        }
        //activeComponent.current = 'dialing'
        //setCurrentComponent('dialing')
        setAddVideo(false)
        currentPeers.current[activeCaller].signalDataChannel.send(JSON.stringify({type: "declineAddVideo", contact: user.email}));

    }

    const onCancelRequest = () => {
        console.log('onCancelRequest');
        let activeCaller = null

        if (selectedContact) {
            activeCaller = selectedContact.email
        } else if (requester) {
            activeCaller = requester
        }
        //activeComponent.current = 'dialing'
        //setCurrentComponent('dialing')
        setAddVideoRequest(false)
        currentPeers.current[activeCaller].signalDataChannel.send(JSON.stringify({type: "cancelAddVideo", contact: user.email}));

    }

    const shareScreen = async() => {
        let activeCaller = null

        if (selectedContact) {
            activeCaller = selectedContact.email
        } else if (requester) {
            activeCaller = requester
        }
        startScreenShare(activeCaller)
    };

    const showContacts = () => {
        //activeComponent.current = 'contacts'
        setCurrentComponent('contacts')
        //storeSelectedContact({});
        //console.log('showContacts', getReference(selectedContactKey), selectedContact);
        // You can do something with innerText, such as updating state or sending it to a server
    };
    
    return (
        <div className="audio-call-container">
          <div className="top-controls">
              <button id="hide-call-button" onClick={showContacts}><i className="bi bi-arrows-angle-contract" style={{ fontSize: '16px' }}></i></button>
              <div>
                <h3 style={{margin: 0, padding: 0}}>{selectedContact ? selectedContact.name : contacts.filter(elem=>elem.email === requester)[0].name}</h3>
                <h5 style={{margin: 0, padding: 0, textAlign: 'center'}}>Connected . . .</h5>
              </div>
              <button id="add-call-button" onClick={handleEndCall}><i className="bi bi-person-add" style={{ fontSize: '16px', transform: 'scaleX(-1)' }}></i></button>
          </div>

          <img src={`${process.env.PUBLIC_URL}/assets/contact-placeholder.png`} alt="image" className="profile-img-main" />

          <audio ref={localAudioRef} autoPlay muted playsInline></audio>
          <audio ref={remoteAudioRef} autoPlay playsInline></audio>

          {addVideo && <div className="video-prompt-modal">
              <div className="video-prompt-content">
                  <h3>Request to Switch to Video Call</h3>
                  <p>The other party has requested to add video to this call. Would you like to enable your video?</p>
                  
                  <div className="video-prompt-buttons">
                  <button onClick={onConfirm} className="confirm-button">Yes, Turn on Video</button>
                  <button onClick={onCancel} className="cancel-button">No, Stay on Audio</button>
                  </div>
              </div>
          </div>}

          {addVideoRequest && <div className="video-prompt-modal">
              <div className="video-prompt-content">
                  <h3>Requesting to Switch to Video Call</h3>
                  <p>Requesting the other party to add video to this call.</p>
                  
                  <div className="video-prompt-buttons">
                  <button onClick={onCancelRequest} className="cancel-button">Cancel</button>
                  </div>
              </div>
          </div>}

          <div className="call-controls">
              <button onClick={startVideoCall}><i className="bi bi-camera-video" style={{ fontSize: '20px' }}></i></button>
              <button onClick={shareScreen}><i className="bi bi-display" style={{ fontSize: '20px' }}></i></button>
              <button onClick={toggleSpeaker}>
                  {isSpeakerOn ? (<i className="bi bi-volume-down" style={{ fontSize: '20px' }}></i>) : (<i className="bi bi-volume-up" style={{ fontSize: '20px' }}></i>)}
              </button>
              <button onClick={toggleMute}>
                  {isMuted ? (<i className="bi bi-mic" style={{ fontSize: '20px' }}></i>) : (<i className="bi bi-mic-mute" style={{ fontSize: '20px' }}></i>)}
              </button>
              <button id="end-call-button" onClick={handleEndCall}><i className="bi bi-telephone" style={{ fontSize: '20px' }}></i></button>
          </div>
      </div>
    );
};

export default AudioCall;
