// src/components/VerifyEmail.js
import React, { useState, useEffect } from "react";
import { auth, db } from "../firebase";
import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { fetchUserProfile } from "../utils/userUtils";
//import { useNavigate } from "react-router-dom";

const VerifyEmail = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  //const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {

        // Try to fetch the user profile from Firestore
        const profile = await fetchUserProfile(user.uid);
        if (profile) {
          //navigate("/");
        } else {
          // Check if the user is verified if profile fetch fails
            await user.reload();  // Refresh the user's authentication state
            if (user.emailVerified) {
              setIsVerified(true);

              // Add the user's profile to Firestore
              await setDoc(doc(db, "users", user.uid), {
                  userProfile: {
                    name: user.displayName,
                    email: user.email,
                  },
                  signal: {}
              });

              // Add the user's profile to Firestore
              await setDoc(doc(db, "incomingConnections", user.uid), {
                  userProfile: {
                    name: user.displayName,
                    email: user.email,
                  },
                  connections: {}
              });

              alert("Email verified and profile saved!");
              //navigate("/");
            }
        }
      } else {
        setIsVerified(false);
      }

    });

    return () => unsubscribe();
  }, []);

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setIsLoggedIn(true);
    } catch (error) {
      console.error("Error logging in:", error);
      alert("Login failed!");
    }
  };

  return (
    <div>
      <h2>Login to Verify Email</h2>
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleLogin}>Log In</button>

      {isVerified ? (
        <p>Your email is verified! Your profile has been saved.</p>
      ) : isLoggedIn && isVerified && (
        <p>Please log in to verify your email.</p>
      )}
    </div>
  );
};

export default VerifyEmail;
