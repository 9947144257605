// AudioCall.js
import React, { useContext, useCallback, useRef, useState, useEffect } from 'react';
import { GlobalContext } from '../utils/GlobalContext';
import { auth, db } from "../firebase";
import { doc, updateDoc, onSnapshot, getDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { ConnectorContext } from '../App';
import { getDocRefByEmail, getIncomingConnectionRefByEmail } from "../utils/userUtils";

const worker = new Worker(new URL('./indexedDB.js', import.meta.url));

const AudioCall = () => {
    const [inputValue, setInputValue] = useState('');
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    // Open the database when component mounts
    worker.postMessage({ action: 'openDB', dbName: 'MyDatabase', storeName: 'MyStore' });
    
    worker.onmessage = (e) => {
      if (e.data.result === 'dbOpened') {
        console.log('Database opened');
      }
      if (e.data.result === 'dataAdded') {
        fetchData();
      }
      if (Array.isArray(e.data.result)) {
        setDataList(e.data.result);
      }
    };
  }, []);

  const handleAddData = () => {
    worker.postMessage({ action: 'addData', dbName: 'MyDatabase', storeName: 'MyStore', data: { value: inputValue } });
    setInputValue('');
  };

  const fetchData = () => {
    worker.postMessage({ action: 'getAllData', dbName: 'MyDatabase', storeName: 'MyStore' });
  };

  return (
    <div>
      <h1>IndexedDB with Web Worker</h1>
      <input value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
      <button onClick={handleAddData}>Add to IndexedDB</button>
      <ul>
        {dataList.map(item => <li key={item.id}>{item.value}</li>)}
      </ul>
    </div>
  );
};

window.worker = worker

export default AudioCall;
