import React, { useContext } from 'react';
import { ConnectorContext } from '../App';

function MessageDisplay() {
  const { message, setCurrentComponent } = useContext(ConnectorContext);  // Access context values

  return (
    <div>
      <h2>Message Display</h2>
      <p>{message ? message : "No message yet"}</p>

      {/* Button to go back to input */}
      <button onClick={() => setCurrentComponent('input')}>Go Back to Input</button>
    </div>
  );
}

export default MessageDisplay;
