// Chats.js
import React, { useContext, useRef, useState, useEffect } from 'react';
import { GlobalContext } from '../utils/GlobalContext';
import { auth, db } from "../firebase";
import { doc, updateDoc, onSnapshot } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { getDocRefByEmail } from "../utils/userUtils";
import { useSelector, useDispatch } from 'react-redux';
import { setInput, setContactsKey, setChatMessages, setCurrentUserKey, setSelectedContactKey, setSelectedContact } from '../utils/globalSlice'; // Adjust the path accordingly
import { useUpdateGlobal } from '../utils/updateGlobal'; // Adjust the path accordingly
import { setReference, getReference, removeReference } from '../utils/referenceStore'; // Adjust the path accordingly
import MarkdownWithSyntaxHighlighting from './MarkdownWithSyntaxHighlighting';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { ConnectorContext } from '../App';

import './Chats.css';
import RichTextEditor from './RichTextEditor';
import DraftEditor from './DraftEditor';
import SlateEditor from './SlateEditor';
import WordProcessor from './WordProcessor';

const RenderedHtml = ({ htmlContent }) => {
    return (
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    );
  };


const Chats = () => {
    const { user, peers, currentPeers, requester, contacts, setContacts, selectedContact, setSelectedContact, input, setInput, chatMessages, setChatMessages, currentChatMessages, setCurrentComponent, activeComponent, currentSelectedContact, updateIncomingConnections, startScreenShare, connectionOpen, activeVideoCall, setActiveVideoCall, activeAudioCall, setActiveAudioCall } = useContext(ConnectorContext);

    const cursorPositionRef = useRef(null);
    const textareaRef = useRef(null);
    const localStreamRef = useRef(null);


    const sendMessage = () => {
        const id = selectedContact.email
        const message = input[id]
        console.log([id])
        console.log(message.trim())
        if (message.trim()) {
            const dataChannel = peers[id]?.chatDataChannel;
            console.log(id, message, peers, chatMessages, dataChannel)
            if (dataChannel && dataChannel.readyState === 'open') {
                dataChannel.send(JSON.stringify({ type: 'chat', message }));
                const updatedChatMessages = {
                    ...currentChatMessages.current,
                    [id]: [...(currentChatMessages.current[id] || []), `${message}`]
                }
                console.log(updatedChatMessages)

                //setReference(chatMessagesKey, updatedChatMessages);
                currentChatMessages.current = updatedChatMessages
                setChatMessages(updatedChatMessages);
                setInputValue('')
            }
        }
    };

    const setInputValue = (value) => {
        const updatedInput = {
            ...input,
            [selectedContact.email]: value
        }
        console.log(updatedInput)
        
        setInput(updatedInput);
    }

    const insertTextAtCursor = (before, after = '') => {
        const textarea = document.getElementById('message-input');
        //handleGetInnerText()
        const id =selectedContact.email
        let start = textarea.selectionStart;
        let end = textarea.selectionEnd;
        //const start = textarea.selectionStart;
        //const end = textarea.selectionEnd;
        let currentInput = divRef.current.innerText;//input[id]

        const selection = window.getSelection();
        if (selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            start = range.startOffset;
            end = range.endOffset;

            console.log('Selection start:', start);
            console.log('Selection end:', end);
        }

        console.log(textarea, start, end, selection)

        if (!currentInput) {
            currentInput = ''
        }
        
        console.log(currentInput)
        const selectedText = currentInput.slice(start, end);

        // If no text is selected, place the cursor between the before and after text
        const isTextSelected = selectedText.length > 0;
        const trimmedText = selectedText.trim();

        // Check for space before or after the selection to avoid breaking words
        const needsLeadingSpace = start > 0 && currentInput[start - 1] !== ' ' && before.trim().length > 0;
        const needsTrailingSpace = end < currentInput.length && currentInput[end] !== ' ' && after.trim().length > 0;

        const leadingSpace = needsLeadingSpace ? ' ' : '';
        const trailingSpace = needsTrailingSpace ? ' ' : '';

        // Construct the new text with the necessary adjustments
        const newText =
            currentInput.slice(0, start) +
            before + selectedText + after +
            //leadingSpace + before + trimmedText + after + trailingSpace +
            currentInput.slice(end);

        // Update the input state
        setInputValue(newText);
        console.log(newText)
/*
        // Calculate cursor position: between `before` and `after` if no text is selected
        const cursorPosition = isTextSelected
            ? start + leadingSpace.length + before.length + trimmedText.length
            : start + leadingSpace.length + before.length;

        textarea.focus();
        console.log(cursorPosition)
        textarea.setSelectionRange(cursorPosition, cursorPosition);*/

        const newCursorPosition = isTextSelected
            ? start + leadingSpace.length + before.length + trimmedText.length
            : start + leadingSpace.length + before.length;

        cursorPositionRef.current = newCursorPosition;

    };
/*
    useEffect(() => {
        const textarea = textareaRef.current;
        if (cursorPositionRef.current !== null) {
            console.log(cursorPositionRef.current)
            textarea.focus();
            textarea.setSelectionRange(cursorPositionRef.current, cursorPositionRef.current);
            cursorPositionRef.current = null;
        }
    }, [input]);*/

    const divRef = useRef(null);

    const handleGetInnerText = () => {
        const innerText = divRef.current.innerText;
        const innerHTML = divRef.current.innerHTML;
        console.log('Inner Text:', innerText, innerHTML);
        // You can do something with innerText, such as updating state or sending it to a server
    };

    const jsonToHtml = (jsonContent) => {
        const contentState = convertFromRaw(jsonContent);
        const htmlContent = stateToHTML(contentState);
        //console.log('Inner Text:', htmlContent);
        return htmlContent
        // You can do something with innerText, such as updating state or sending it to a server
    };

    const showContacts = () => {
        activeComponent.current = 'contacts'
        setCurrentComponent('contacts')
        //storeSelectedContact({});
        //console.log('showContacts', getReference(selectedContactKey), selectedContact);
        // You can do something with innerText, such as updating state or sending it to a server
    };

    const audioCall = (contact) => {
        if (currentPeers.current[contact.email].remoteStream && currentPeers.current[contact.email].remoteStream.id && currentPeers.current[contact.email].localStream && currentPeers.current[contact.email].localStream.id && activeAudioCall) {
            activeComponent.current = 'audioCall'
            setCurrentComponent('audioCall')
        } else {
            activeComponent.current = 'dialingAudio'
            setCurrentComponent('dialingAudio')
            currentPeers.current[contact.email].signalDataChannel.send(JSON.stringify({type: "audioCall", contact: user.email}));
        }
    };
    
    const videoCall = async(contact) => {
        if (currentPeers.current[contact.email].remoteStream && currentPeers.current[contact.email].remoteStream.id && currentPeers.current[contact.email].localStream && currentPeers.current[contact.email].localStream.id && activeVideoCall) {
            activeComponent.current = 'videoCall'
            setCurrentComponent('videoCall')
        } else {
            activeComponent.current = 'dialing'
            setCurrentComponent('dialing')
            currentPeers.current[contact.email].signalDataChannel.send(JSON.stringify({type: "videoCall", contact: user.email}));

        }
        
    };
    
    const shareScreen = async(contact) => {
        startScreenShare(contact.email)
    };

    const transferFile = async(contact) => {
        activeComponent.current = 'fileTransfer'
        setCurrentComponent('fileTransfer')
    };


    return (
        <div style={{margin: 0, padding: 0}}>
            {selectedContact.email && <div className="chat-app">
                <div style={{margin: 0, padding: 0}}>
                    <div style={{ display: "flex" }}>
                        <span onClick={showContacts} style={{ margin: "15px 15px 5px 15px" }}><i className="bi bi-caret-left" style={{ color: "#fdb271", fontSize: "28px" }}></i></span>
                        <div>
                            <h3 style={{ marginBottom: "10px" }}>{selectedContact ? selectedContact.name : contacts.filter(elem=>elem.email === requester)[0].name}</h3>
                        </div>
                        <span></span>
                    </div>
                    {connectionOpen && <div>
                        <i className="bi bi-telephone-outbound" onClick={() => audioCall(selectedContact)} style={{ color: '#fdb271', fontSize: '24px', margin: '20px' }}></i>
                        <i className="bi bi-camera-video" onClick={() => videoCall(selectedContact)} style={{ color: '#fdb271', fontSize: '24px', margin: '20px' }}></i>
                        <i className="bi bi-display" onClick={() => shareScreen(selectedContact)} style={{ color: '#fdb271', fontSize: '24px', margin: '20px' }}></i>
                        <i className="bi bi-folder-symlink" onClick={() => transferFile(selectedContact)} style={{ color: '#fdb271', fontSize: '24px', margin: '20px' }}></i>
                    </div>}
                </div>
                <div className="chat-window" style={{margin: 0, padding: 0, width: '100%', maxHeight: '40vh'}}>
                    {(chatMessages[selectedContact.email] || []).map((message, index) => (
                        <div key={index} className="chat-message">
                            <RenderedHtml htmlContent={jsonToHtml(message.message)} />
                        </div>
                    ))}
                </div>
                <div className="chat-input">
                    <WordProcessor />
                </div>
            </div>}
        </div>
    );
};

export default Chats;
