import React, { useContext, useState, useEffect, useRef } from 'react';
import { ConnectorContext } from '../App';
import QRCode from 'qrcode.react'; // Import the QRCode generator
import { FaPaste } from "react-icons/fa"; // Import a paste icon from react-icons


const ManualWebRTC = () => {
  const { user, peers, currentPeers, contacts, setContacts, handleCandidate, configuration, createPeerConnection, selectedContact, handleOffer, handleAnswer, input, setInput, chatMessages, setChatMessages, currentChatMessages, setCurrentComponent, activeComponent, currentSelectedContact, updateIncomingConnections, startScreenShare, connectionOpen, activeVideoCall, setActiveVideoCall, activeAudioCall, setActiveAudioCall } = useContext(ConnectorContext);

  const [localOffer, setLocalOffer] = useState('');
  const [remoteOffer, setRemoteOffer] = useState('');
  const [localAnswer, setLocalAnswer] = useState('');
  const [remoteAnswer, setRemoteAnswer] = useState('');
  const [localIceCandidates, setLocalIceCandidates] = useState([]);
  const [remoteIceCandidates, setRemoteIceCandidates] = useState('');
  const [status, setStatus] = useState('Idle');

  const peerConnection = useRef(null);

  // Create and set a local offer
  const createOffer = async () => {
    configuration.current = {}
    createPeerConnection(selectedContact.email);
  };
  
  useEffect(() => {
     console.log(peers)
      if (currentPeers.current[selectedContact.email]) {
        if (currentPeers.current[selectedContact.email].connection.localDescription) {
          setStatus(currentPeers.current[selectedContact.email].connection.connectionState)
          if (currentPeers.current[selectedContact.email].connection.localDescription.type === 'offer') {
            setLocalOffer(JSON.stringify(currentPeers.current[selectedContact.email].connection.localDescription))
          } else {
            setLocalAnswer(JSON.stringify(currentPeers.current[selectedContact.email].connection.localDescription))
          }
        }
        if (currentPeers.current[selectedContact.email].connection.iceGatheringState === "complete") {
          setLocalIceCandidates(currentPeers.current[selectedContact.email].localICECandidates)
        }
      }
  }, [currentPeers, peers, status]);

  // Set remote offer and create an answer
  const handleRemoteOffer = async () => {
    if (!remoteOffer) {
      alert('Please paste a remote offer.');
      return;
    }

    configuration.current = {}
    handleOffer(selectedContact.email, JSON.parse(remoteOffer), user.email, true)
  };

  // Handle remote answer
  const handleRemoteAnswer = async () => {
    if (!remoteAnswer) {
      alert('Please paste a remote answer.');
      return;
    }

    handleAnswer(selectedContact.email, JSON.parse(remoteAnswer), user.email, true)
  };

  // Add remote ICE candidates manually
  const addRemoteIceCandidates = async () => {
    if (!remoteIceCandidates) {
      alert('Please paste remote ICE candidates.');
      return;
    }

    const candidates = remoteIceCandidates
      .trim()
      .split('\n')
      .map((candidate) => JSON.parse(candidate));

    console.log(selectedContact.email, currentPeers, candidates)

    for (let candidate of candidates) {
      try {
        handleCandidate(selectedContact.email, candidate)
        //await peerConnection.current.addIceCandidate(new RTCIceCandidate(candidate));
      } catch (error) {
        console.error('Error adding remote ICE candidate', error);
      }
    }

    setStatus('ICE candidates added');
  };

  const showContacts = () => {
    activeComponent.current = 'contacts'
    setCurrentComponent('contacts')
  };
  
  const scan = () => {
    activeComponent.current = 'scan'
    setCurrentComponent('scan')
  };

  const [text, setText] = useState("");
  const [editing, setEditing] = useState(false);
  const [currentContact, setCurrentContact] = useState({});

  // Function to handle pasting clipboard contents into textarea
  const handlePasteClick = async (type) => {
    try {
      const clipboardText = await navigator.clipboard.readText();
      if (type === "remoteOffer") {
        setRemoteOffer(clipboardText)
      } else if (type === "remoteAnswer") {
        setRemoteAnswer(clipboardText)
      } else if (type === "remoteIceCandidates") {
        setRemoteIceCandidates(clipboardText)
      }
      setText((prevText) => prevText + clipboardText); // Append clipboard text to current textarea content
    } catch (error) {
      console.error("Failed to read clipboard contents: ", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentContact({ ...currentContact, [name]: value });
  };

  const handleUpdateContact = async () => {
    if (!user || currentContact.email === '') return;

    const updatedContacts = contacts.map((contact) =>
      contact.email === currentContact.email ? currentContact : contact
    );

    setContacts(updatedContacts);
    setEditing(false)
  };

  useEffect(() => {
    setCurrentContact(selectedContact)
  }, []);

  return (
    <div>
      <h2>Manual Connection to {selectedContact.email}</h2>
      <button onClick={showContacts}>Back</button>
      <button onClick={scan}>Scan</button>
      <button onClick={() => setEditing(true)}>Edit</button>
      <div>Status: {status}</div>

      {editing && <div>
        <h4>Enter Contact Details</h4>
        <input
        type="text"
        name="name"
        placeholder="Contact Name"
        value={currentContact.name}
        onChange={handleChange}
        />
        <input
        type="email"
        name="email"
        placeholder="Contact Email"
        value={currentContact.email}
        onChange={handleChange}
        />
        <button onClick={handleUpdateContact}>Add Contact</button>
      </div>}

      {/* Local Offer */}
      {status !== 'connected' && <div>
        <h3>Local Offer</h3>
        <button onClick={createOffer}>Create Offer</button>
        {localOffer && (
          <>
            <textarea
              readOnly
              value={localOffer}
              rows="6"
              style={{ width: '100%' }}
              placeholder="Your local offer will appear here"
            />
            <div>
              <h4>Local Offer QR Code</h4>
              <QRCode value={localOffer} size={256} style={{marginLeft: '10px'}} />
            </div>
          </>
        )}
      </div>}

      {/* Remote Offer */}
      {status !== 'connected' && <div>
        <h3>Remote Offer</h3>
        {/* Paste icon button */}
        
        <textarea
          value={remoteOffer}
          onChange={(e) => setRemoteOffer(e.target.value)}
          rows="6"
          style={{ width: '100%' }}
          placeholder="Paste remote offer here"
        />
        <button
          onClick={() => handlePasteClick("remoteOffer")}
          style={{ display: "flex", alignItems: "center", gap: "5px", cursor: "pointer", padding: "10px", backgroundColor: "#4CAF50", color: "white", border: "none", borderRadius: "5px" }}
        >
          <FaPaste /> Paste from Clipboard
        </button>
        <button onClick={handleRemoteOffer}>Set Remote Offer & Create Answer</button>
      </div>}

      {/* Local Answer */}
      {status !== 'connected' && <div>
        <h3>Local Answer</h3>
        {localAnswer && (
          <>
            <textarea
              readOnly
              value={localAnswer}
              rows="6"
              style={{ width: '100%' }}
              placeholder="Your local answer will appear here"
            />
            <div>
              <h4>Local Answer QR Code</h4>
              <QRCode value={localAnswer} size={256} style={{marginLeft: '10px'}} />
            </div>
          </>
        )}
      </div>}

      {/* Remote Answer */}
      {status !== 'connected' && <div>
        <h3>Remote Answer</h3>
        <textarea
          value={remoteAnswer}
          onChange={(e) => setRemoteAnswer(e.target.value)}
          rows="6"
          style={{ width: '100%' }}
          placeholder="Paste remote answer here"
        />
        <button
          onClick={() => handlePasteClick("remoteAnswer")}
          style={{ display: "flex", alignItems: "center", gap: "5px", cursor: "pointer", padding: "10px", backgroundColor: "#4CAF50", color: "white", border: "none", borderRadius: "5px" }}
        >
          <FaPaste /> Paste from Clipboard
        </button>
        <button onClick={handleRemoteAnswer}>Set Remote Answer</button>
      </div>}

      {/* Local ICE Candidates */}
      {status !== 'connected' && <div>
        <h3>Local ICE Candidates</h3>
        {localIceCandidates.length > 0 && (
          <>
            <textarea
              readOnly
              value={localIceCandidates.map((candidate) => JSON.stringify(candidate)).join('\n')}
              rows="6"
              style={{ width: '100%' }}
              placeholder="Your local ICE candidates will appear here"
            />
            <div>
              <h4>Local ICE Candidates QR Code</h4>
              <QRCode
                value={JSON.stringify({type: "candidates", candidates: localIceCandidates.map((candidate) => JSON.stringify(candidate)).join('\n')})}
                size={256}
                style={{marginLeft: '10px'}}
              />
            </div>
          </>
        )}
      </div>}

      {/* Remote ICE Candidates */}
      {status !== 'connected' && <div>
        <h3>Remote ICE Candidates</h3>
        <textarea
          value={remoteIceCandidates}
          onChange={(e) => setRemoteIceCandidates(e.target.value)}
          rows="6"
          style={{ width: '100%' }}
          placeholder="Paste remote ICE candidates here, one per line"
        />
        <button
          onClick={() => handlePasteClick("remoteIceCandidates")}
          style={{ display: "flex", alignItems: "center", gap: "5px", cursor: "pointer", padding: "10px", backgroundColor: "#4CAF50", color: "white", border: "none", borderRadius: "5px" }}
        >
          <FaPaste /> Paste from Clipboard
        </button>
        <button onClick={addRemoteIceCandidates}>Add Remote ICE Candidates</button>
      </div>}
    </div>
  );
};

export default ManualWebRTC;
