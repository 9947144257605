// VideoCall.js
import React, { useContext, useCallback, useRef, useState, useEffect } from 'react';
import { GlobalContext } from '../utils/GlobalContext';
import { auth, db } from "../firebase";
import { doc, updateDoc, onSnapshot, getDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { ConnectorContext } from '../App';
import { getDocRefByEmail, getIncomingConnectionRefByEmail } from "../utils/userUtils";
import "./VideoCall.css";
import "./AudioCall.css";

const VideoCall = () => {
    const { user, peers, setPeers, currentPeers, contacts, setContacts, selectedContact, setSelectedContact, requester, setRequester, setCurrentComponent, createPeerConnection, fullScreenVideoRef, floatingVideoRef, localStream, remoteStream, activeComponent, endCall, startScreenShare, activeVideoCall, setActiveVideoCall, activeAudioCall, setActiveAudioCall } = useContext(ConnectorContext);
    const localVideoRef = useRef(null);
    const localStreamRef = useRef(null);
    
    const startLocalStream = useCallback(async (deviceId = null) => {
        const constraints = {
            video: deviceId ? { deviceId: { exact: deviceId } } : true,
            audio: true
        };

        try {
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            if (localStreamRef.current) {
                // Stop the existing tracks before replacing them with the new stream
                localStreamRef.current.getTracks().forEach(track => track.stop());
            }
            //localVideoRef.current.srcObject = stream;
            floatingVideoRef.current.srcObject = stream;
            localStreamRef.current = stream;
            localStream = stream

            console.log(currentPeers)

            // Replace the video track in the peer connections
            Object.values(currentPeers.current).forEach(peer => {
                const videoTrack = stream.getVideoTracks()[0];
                if (peer.connection.getSenders()) {
                    const sender = peer.connection.getSenders().find(s => s.track.kind === 'video');
                    if (sender) {
                        sender.replaceTrack(videoTrack);
                    }
                }
            });
        } catch (error) {
            console.error('Error accessing media devices.', error);
        }
    }, [peers, currentPeers]);

        const [peerId, setPeerId] = useState('');
        const [chatMessages, setChatMessages] = useState({});
        const [videoDevices, setVideoDevices] = useState([]);
        const [selectedDeviceId, setSelectedDeviceId] = useState(null);
        //const [iCECandidates, setICECandidates] = useState([]);
        //let iCECandidates = [];
/*
        const startScreenShare = async () => {
            try {
                const screenStream = await navigator.mediaDevices.getDisplayMedia({ video: true });
                const videoTrack = screenStream.getVideoTracks()[0];

                // Replace the video track on the peer connection
                for (const peer of Object.values(peers)) {
                    const sender = peer.connection.getSenders().find(s => s.track.kind === 'video');
                    if (sender) {
                        sender.replaceTrack(videoTrack);
                    }
                }

                // Set the local video to the screen stream
                localVideoRef.current.srcObject = screenStream;
                localStreamRef.current = screenStream;

                // Listen for when the screen share ends
                videoTrack.onended = () => {
                    startLocalStream(selectedDeviceId);
                };
            } catch (error) {
                console.error('Error sharing screen:', error);
            }
        };*/

    
        const [isLocalFullScreen, setIsLocalFullScreen] = useState(false);
        const [isMinimized, setIsMinimized] = useState(false);
        const [isMuted, setIsMuted] = useState(false);
        const [isCameraOff, setIsCameraOff] = useState(false);
        const [isFrontCamera, setIsFrontCamera] = useState(true);
        const [floatingVideoPosition, setFloatingVideoPosition] = useState({ x: 20, y: 20 });

        const floatingVideoContainerRef = useRef(null);
        
      
        useEffect(() => {
          // Initialize video sources on mount
          if (fullScreenVideoRef.current && floatingVideoRef.current) {
            fullScreenVideoRef.current.srcObject = isLocalFullScreen
              ? localStream
              : remoteStream;
            floatingVideoRef.current.srcObject = isLocalFullScreen
              ? remoteStream
              : localStream;
          }
        }, [localStream, remoteStream, isLocalFullScreen]);
      
        // Switch between local and remote video being full screen
        const toggleFullScreen = useCallback(() => {
          setIsLocalFullScreen((prev) => {
            // Update video sources when switching
            if (fullScreenVideoRef.current && floatingVideoRef.current) {
              fullScreenVideoRef.current.srcObject = prev ? remoteStream : localStream;
              floatingVideoRef.current.srcObject = prev ? localStream : remoteStream;
            }
            return !prev;
          });
        }, [localStream, remoteStream]);
      
        // Handle minimizing the floating video
        const handleMinimize = () => {
          setIsMinimized((prev) => !prev);
        };
        
        
        const handleEndCall = () => {
            let activeCaller = null

            if (selectedContact) {
                activeCaller = selectedContact.email
            } else if (requester) {
                activeCaller = requester
            }
            currentPeers.current[activeCaller].signalDataChannel.send(JSON.stringify({type: "endCall", contact: user.email}));
            endCall(activeCaller)
            setActiveVideoCall(true)
        };

        // Mute/Unmute the local audio stream
        const toggleMute = () => {
            const audioTrack = localStream.getAudioTracks()[0];
            if (audioTrack) {
            audioTrack.enabled = !audioTrack.enabled;
            setIsMuted(!audioTrack.enabled);
            }
        };

        // Turn camera on/off
        const toggleCamera = () => {
            const videoTrack = localStream.getVideoTracks()[0];
            if (videoTrack) {
            videoTrack.enabled = !videoTrack.enabled;
            setIsCameraOff(!videoTrack.enabled);
            }
        };

        // Switch camera (for devices with multiple cameras)
        const switchCamera = async () => {
            const videoTrack = localStream.getVideoTracks()[0];
            const currentDeviceId = videoTrack.getSettings().deviceId;

            videoTrack.stop();

            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = devices.filter((device) => device.kind === "videoinput");

            const nextCameraDevice = videoDevices.find(
                (device) => device.deviceId !== currentDeviceId
            ) || videoDevices[0];

            const newStream = await navigator.mediaDevices.getUserMedia({
                video: { deviceId: nextCameraDevice.deviceId },
                audio: true,
            });

            const newVideoTrack = newStream.getVideoTracks()[0];
            localStream.removeTrack(videoTrack);
            localStream.addTrack(newVideoTrack);

            let activeCaller = null

            if (selectedContact) {
                activeCaller = selectedContact.email
            } else if (requester) {
                activeCaller = requester
            }
            
            const sender = currentPeers.current[activeCaller].connection.getSenders().find(s => s.track.kind === 'video');
            if (sender) {
                sender.replaceTrack(newVideoTrack);
            }

            if (isLocalFullScreen) {
                fullScreenVideoRef.current.srcObject = localStream;
            } else {
                floatingVideoRef.current.srcObject = localStream;
            }

            setIsFrontCamera(
            nextCameraDevice.label.toLowerCase().includes("front")
            );
        };

        // Dragging for both desktop and mobile
        const handleDragStart = (event, isTouch = false) => {
            const startX = (isTouch ? event.touches[0].clientX : event.clientX) - floatingVideoPosition.x;
            const startY = (isTouch ? event.touches[0].clientY : event.clientY) - floatingVideoPosition.y;

            const handleDragMove = (moveEvent) => {
            const clientX = isTouch ? moveEvent.touches[0].clientX : moveEvent.clientX;
            const clientY = isTouch ? moveEvent.touches[0].clientY : moveEvent.clientY;

            setFloatingVideoPosition({
                x: clientX - startX,
                y: clientY - startY,
            });
            };

            const handleDragEnd = () => {
            document.removeEventListener(isTouch ? "touchmove" : "mousemove", handleDragMove);
            document.removeEventListener(isTouch ? "touchend" : "mouseup", handleDragEnd);
            };

            document.addEventListener(isTouch ? "touchmove" : "mousemove", handleDragMove);
            document.addEventListener(isTouch ? "touchend" : "mouseup", handleDragEnd);
        };

        const shareScreen = async() => {
          let activeCaller = null

          if (selectedContact) {
              activeCaller = selectedContact.email
          } else if (requester) {
              activeCaller = requester
          }
          startScreenShare(activeCaller)
        };

        const showContacts = () => {
          //activeComponent.current = 'contacts'
          setCurrentComponent('contacts')
          //storeSelectedContact({});
          //console.log('showContacts', getReference(selectedContactKey), selectedContact);
          // You can do something with innerText, such as updating state or sending it to a server
        };

        return (
          <div className="video-call-container">
            <div style={{zIndex: 2000}} className="top-controls">
              <button id="hide-call-button" onClick={showContacts}><i className="bi bi-arrows-angle-contract" style={{ fontSize: '16px' }}></i></button>
              <div>
                <h3 style={{margin: 0, padding: 0}}>{selectedContact ? selectedContact.name : contacts.filter(elem=>elem.email === requester)[0].name}</h3>
                <h5 style={{margin: 0, padding: 0, textAlign: 'center'}}>Connected . . .</h5>
              </div>
              <span id="call-button" onClick={handleEndCall}></span>
            </div>
            {/* Fullscreen video */}
            <div className="fullscreen-video">
              <video
                ref={fullScreenVideoRef}
                className="video"
                autoPlay
                playsInline
                muted={isLocalFullScreen}
              ></video>
            </div>
      
            {/* Small floating video */}
            <div
              className={`floating-video ${isMinimized ? "minimized" : ""}`}
              ref={floatingVideoContainerRef}
              style={{
                top: `${floatingVideoPosition.y}px`,
                left: `${floatingVideoPosition.x}px`,
                position: "absolute",
              }}
              onMouseDown={(event) => handleDragStart(event, false)}
              onTouchStart={(event) => handleDragStart(event, true)} // For mobile devices
            >
              <div className="floating-video-controls">
                <button onClick={toggleFullScreen}>
                  {isLocalFullScreen ? (<i className="bi bi-arrow-repeat" style={{ fontSize: '20px' }}></i>) : (<i className="bi bi-arrow-repeat" style={{ fontSize: '20px' }}></i>)}
                </button>
                <button onClick={handleMinimize}>
                  {isMinimized ? (<i className="bi bi-arrow-up-square" style={{ fontSize: '20px' }}></i>) : (<i className="bi bi-arrow-down-square" style={{ fontSize: '20px' }}></i>)}
                </button>
              </div>
              {!isMinimized && (
                <video
                  ref={floatingVideoRef}
                  className="video small-video"
                  autoPlay
                  playsInline
                  muted={!isLocalFullScreen}
                ></video>
              )}
            </div>

            {/* Controls for mute, camera, and switch camera */}
            <div className="call-controls">
              <button onClick={toggleCamera}>
                  {isCameraOff ? (<i className="bi bi-camera-video" style={{ fontSize: '20px' }}></i>) : (<i className="bi bi-camera-video-off" style={{ fontSize: '20px' }}></i>)}
              </button>
              <button onClick={shareScreen}><i className="bi bi-display" style={{ fontSize: '20px' }}></i></button>
              <button onClick={switchCamera}>
                  {isFrontCamera ? (<i className="bi bi-camera2" style={{ fontSize: '20px' }}></i>) : (<i className="bi bi-webcam" style={{ fontSize: '20px' }}></i>)}
              </button>
              <button onClick={toggleMute}>
                  {isMuted ? (<i className="bi bi-mic" style={{ fontSize: '20px' }}></i>) : (<i className="bi bi-mic-mute" style={{ fontSize: '20px' }}></i>)}
              </button>
              <button id="end-call-button" onClick={handleEndCall}><i className="bi bi-telephone" style={{ fontSize: '20px' }}></i></button>
            </div>
          </div>
        );
};

export default VideoCall;