import React from 'react';
import './ChatWindow.css'; // CSS file for chat window styling

const messages = [
    { id: 1, text: 'Hey! How’s it going?', sender: 'incoming', avatar: 'path_to_avatar.jpg', time: '2:32 PM' },
    { id: 2, text: 'I’m good, how about you?', sender: 'outgoing', avatar: '', time: '2:33 PM' },
  ];

const ChatWindow = () => {
    return (
        <div className="chat-window-modern">
          <div className="chat-header">
            <button className="back-button">←</button>
            <div className="chat-title">Poppy Delevingne</div>
          </div>
          <div className="message-area">
            {messages.map(message => (
              <div className={`message-bubble ${message.sender}`} key={message.id}>
                {message.sender === 'incoming' && (
                  <img src={message.avatar} alt="Avatar" className="message-avatar" />
                )}
                <div className="message-text">{message.text}</div>
                <div className="message-time">{message.time}</div>
              </div>
            ))}
          </div>
          <div className="chat-input">
            <input type="text" className="input-field" placeholder="Type a message..." />
            <button className="send-button">✈️</button>
          </div>
        </div>
      );
    };

export default ChatWindow;
