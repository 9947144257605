import React, { useState } from 'react';
import Signup from '../components/Signup';
import VerifyEmail from '../components/VerifyEmail';

const Login = () => {
    const [isLoginVisible, setIsLoginVisible] = useState(true);

  return (
    <div className='Login' style={{ marginTop: '20px' }}>
        
        {isLoginVisible && <VerifyEmail />}
        {!isLoginVisible && <Signup />}
        {isLoginVisible && <p>
            Don't have an account? <button onClick={() => setIsLoginVisible(!isLoginVisible)}>
                Sign Up
            </button>
        </p>}
        {!isLoginVisible && <p>
            Already have an account? <button onClick={() => setIsLoginVisible(!isLoginVisible)}>
                Log In
            </button>
        </p>}     
    </div>
  )
}

export default Login