import React from 'react'

const Conversation = ({Conversation}) => {
  return (
    <div className='Conversation' style={{display: "flex"}}>
        <div style={{margin: "10px", width: "20%"}}>Image</div>
        <div style={{margin: "10px", width: "75%", display: "flex"}}>
            <div style={{align: "left"}}>{Conversation.name}</div>
            <div style={{alignItems: "right"}}>Time</div>
        </div>
        
    </div>
  )
}

export default Conversation