import React, { useContext, useRef, useState, useEffect } from "react";
import { ConnectorContext } from '../App';
import QrScanner from "qr-scanner";
//import "./QRScanner.css"; // Custom CSS file for styling

const QRScanner = () => {

  const { user, peers, currentPeers, localOffer, localAnswer, localIceCandidates, handleCandidate, configuration, createPeerConnection, selectedContact, handleOffer, handleAnswer, input, setInput, chatMessages, setChatMessages, currentChatMessages, setCurrentComponent, activeComponent, currentSelectedContact, updateIncomingConnections, startScreenShare, connectionOpen, activeVideoCall, setActiveVideoCall, activeAudioCall, setActiveAudioCall } = useContext(ConnectorContext);

  const videoRef = useRef(null); // Reference to video element
  const canvasRef = useRef(null); // Reference to canvas element
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [isCameraActive, setIsCameraActive] = useState(false); // Track if camera is active

  // Function to start the camera manually
  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });

      if (videoRef.current) {
        videoRef.current.srcObject = stream; // Ensure the video element is ready
        setIsCameraActive(true); // Mark camera as active
      } else {
        setError("Camera element not found.");
      }
    } catch (err) {
      // Check the type of error and handle accordingly
      if (err.name === "NotAllowedError") {
        setError("Camera access was denied. Please allow camera access in your browser settings.");
      } else if (err.name === "NotFoundError") {
        setError("No camera was found on this device.");
      } else if (err.name === "NotReadableError") {
        setError("Unable to access the camera. Another application might be using it.");
      } else if (err.name === "OverconstrainedError") {
        setError("The camera constraints cannot be satisfied.");
      } else {
        setError("Camera could not be started due to an unknown error.");
      }
      console.error(err);
    }
  };

  // Function to stop the camera
  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
      setIsCameraActive(false); // Mark camera as inactive
    }
  };

  // Capture and scan image from video feed
  const captureAndScan = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    if (video && context) {
      // Draw the current video frame onto the canvas
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Convert the canvas to an image file and scan it
      canvas.toBlob((blob) => {
        QrScanner.scanImage(blob, { returnDetailedScanResult: true })
          .then((scanResult) => {
            setResult(scanResult.data);
            try {
              const currentSignal = JSON.parse(scanResult.data)
              console.log(currentSignal)
              if (currentSignal.type === 'offer') {
                configuration.current = {}
                handleOffer(selectedContact.email, currentSignal, user.email, true)
                activeComponent.current = 'manual'
                setCurrentComponent('manual')
              } else if (currentSignal.type === 'answer') {
                configuration.current = {}
                handleAnswer(selectedContact.email, currentSignal, user.email, true)
                activeComponent.current = 'manual'
                setCurrentComponent('manual')
              } else if (currentSignal.type === 'candidates') {
                const candidates = currentSignal.candidates
                  .trim()
                  .split('\n')
                  .map((candidate) => JSON.parse(candidate));
  
                console.log(selectedContact.email, currentPeers, candidates)
  
                for (let candidate of candidates) {
                  try {
                    handleCandidate(selectedContact.email, candidate)
                    //await peerConnection.current.addIceCandidate(new RTCIceCandidate(candidate));
                  } catch (error) {
                    console.error('Error adding remote ICE candidate', error);
                  }
                }
                activeComponent.current = 'manual'
                setCurrentComponent('manual')
              }
            } catch (error) {
              console.error(error);
            }
            setError(null); // Clear any previous errors
          })
          .catch((err) => {
            setResult(null); // Clear previous result
            setError("No QR code found or error scanning image.");
            console.error(err);
          });
      });
    } else {
      setError("No video feed available to capture.");
    }
  };

  // Function to handle image upload and scan the image
  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      QrScanner.scanImage(file, { returnDetailedScanResult: true })
        .then((scanResult) => {
          setResult(scanResult.data);
          try {
            const currentSignal = JSON.parse(scanResult.data)
            console.log(currentSignal)
            if (currentSignal.type === 'offer') {
              configuration.current = {}
              handleOffer(selectedContact.email, currentSignal, user.email, true)
              activeComponent.current = 'manual'
              setCurrentComponent('manual')
            } else if (currentSignal.type === 'answer') {
              handleAnswer(selectedContact.email, currentSignal, user.email, true)
              activeComponent.current = 'manual'
              setCurrentComponent('manual')
            } else if (currentSignal.type === 'candidates') {
              const candidates = currentSignal.candidates
                .trim()
                .split('\n')
                .map((candidate) => JSON.parse(candidate));

              console.log(selectedContact.email, currentPeers, candidates)

              for (let candidate of candidates) {
                try {
                  handleCandidate(selectedContact.email, candidate)
                  //await peerConnection.current.addIceCandidate(new RTCIceCandidate(candidate));
                } catch (error) {
                  console.error('Error adding remote ICE candidate', error);
                }
              }
              activeComponent.current = 'manual'
              setCurrentComponent('manual')
            }
          } catch (error) {
            console.error(error);
          }
          setError(null); // Clear any previous errors
        })
        .catch((err) => {
          setResult(null); // Clear previous result
          setError("No QR code found or error scanning image.");
          console.error(err);
        });
    }
  };

  const showSetup = () => {
    activeComponent.current = 'manual'
    setCurrentComponent('manual')
  };

  return (
    <div className="qr-scanner-container">
      <h2>QR Code Scanner</h2>
      <button onClick={showSetup}>Back</button>
      {/* Option to upload an image */}
      <div>
        <h3>Upload an image to scan</h3>
        <input type="file" accept="image/*" onChange={handleImageUpload} />
      </div>

      {/* Option to start the camera */}
      {!isCameraActive && (
        <div>
          <h3>Or scan using your camera</h3>
          <button onClick={startCamera}>Start Camera</button>
        </div>
      )}

      {/* The video element is always rendered but hidden if not in use */}
      <div style={{ display: isCameraActive ? 'block' : 'none' }}>
        <video ref={videoRef} autoPlay style={{ width: "100%", height: "auto" }} />
        <button onClick={captureAndScan}>Capture & Scan QR Code</button>
        <button onClick={stopCamera}>Stop Camera</button>
      </div>

      <canvas ref={canvasRef} style={{ display: "none" }} width="640" height="480"></canvas>

      {/* Display the result */}
      {result && (
        <div className="result">
          <h2>QR Code Result:</h2>
          <p>{result}</p>
        </div>
      )}

      {/* Display errors */}
      {error && (
        <div className="error">
          <h2>Error:</h2>
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export default QRScanner;
