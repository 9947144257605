import React, { useRef, useState, useEffect } from "react";

const DraggableVideo = ({ stream }) => {
  const videoRef = useRef(null);
  const floatingVideoRef = useRef(null);

  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

  // Attach the stream to the video element
  useEffect(() => {
    if (stream && videoRef.current) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  // Function to start dragging
  const startDrag = (e) => {
    setDragging(true);

    if (e.type === "mousedown") {
      setDragStart({
        x: e.clientX - position.x,
        y: e.clientY - position.y,
      });
    } else if (e.type === "touchstart") {
      setDragStart({
        x: e.touches[0].clientX - position.x,
        y: e.touches[0].clientY - position.y,
      });
    }
  };

  // Function to handle dragging movement
  const handleDrag = (e) => {
    if (dragging) {
      if (e.type === "mousemove") {
        setPosition({
          x: e.clientX - dragStart.x,
          y: e.clientY - dragStart.y,
        });
      } else if (e.type === "touchmove") {
        setPosition({
          x: e.touches[0].clientX - dragStart.x,
          y: e.touches[0].clientY - dragStart.y,
        });
      }
    }
  };

  // Function to stop dragging
  const stopDrag = () => {
    setDragging(false);
  };

  useEffect(() => {
    // Add event listeners for dragging on mount
    window.addEventListener("mousemove", handleDrag);
    window.addEventListener("touchmove", handleDrag);
    window.addEventListener("mouseup", stopDrag);
    window.addEventListener("touchend", stopDrag);

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener("mousemove", handleDrag);
      window.removeEventListener("touchmove", handleDrag);
      window.removeEventListener("mouseup", stopDrag);
      window.removeEventListener("touchend", stopDrag);
    };
  }, [dragging, dragStart]);

  return (
    <div
      ref={floatingVideoRef}
      onMouseDown={startDrag}
      onTouchStart={startDrag}
      style={{
        position: "absolute",
        left: `${position.x}px`,
        top: `${position.y}px`,
        cursor: "grab",
        width: "30vw",
        height:"30vh",
        border: "1px solid #ccc",
        borderRadius: "10px",
        zIndex: 1000,
      }}
    >
      <video ref={videoRef} autoPlay muted style={{ width: "30vw", height:"30vh", borderRadius: "10px", margin: 0, padding: 0 }} />
    </div>
  );
};

export default DraggableVideo;
