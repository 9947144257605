import { remark } from 'remark';
import html from 'remark-html';
import DOMPurify from 'dompurify';

/**
 * Convert Markdown to HTML and log it to the console.
 * 
 * @param {string} markdown - The Markdown text to convert.
 */
const convertMarkdownToHtmlAndLog = async (markdown) => {
    try {
        // Create a processor with the `remark-html` plugin
        const processor = remark().use(html);

        // Convert Markdown to HTML
        const file = await processor.process(markdown);
        console.log(file)
        const htmlContent = file.toString();

        return DOMPurify.sanitize(htmlContent);
    } catch (error) {
        console.error('Error converting Markdown to HTML:', error);
    }
};

export default convertMarkdownToHtmlAndLog;