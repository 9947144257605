// src/utils/userUtils.js
import { collection, query, where, getDocs, getDoc, doc } from "firebase/firestore";
import { db } from "../firebase";

export const fetchUserProfile = async (userId) => {
  try {
    const docRef = doc(db, "users", userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching user profile:", error);
    return null;
  }
};

export const getDocRefByEmail = async (email) => {
  try {
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("userProfile.email", "==", email));

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0]; // Assuming email is unique and you get one document
      const docRef = doc(db, "users", userDoc.id);
      return docRef;
    } else {
      console.log("No user found with the given email.");
      return null;
    }
  } catch (error) {
    console.error("Error getting document reference by email:", error);
    return null;
  }
};

export const getIncomingConnectionRefByEmail = async (email) => {
  try {
    const usersRef = collection(db, "incomingConnections");
    const q = query(usersRef, where("userProfile.email", "==", email));
    
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0]; // Assuming email is unique and you get one document
      const docRef = doc(db, "incomingConnections", userDoc.id);
      return docRef;
    } else {
      console.log("No user found with the given email.");
      return null;
    }
  } catch (error) {
    console.error("Error getting document reference by email:", error);
    return null;
  }
};