import React from 'react';
import './ChatList.css'; // CSS file for styling

const chats = [
  {
    id: 1,
    name: 'Poppy Delevingne',
    message: 'Typing...',
    avatar: 'path_to_poppy_avatar.jpg', // Avatar image path
    time: '21:30 pm',
    unread: true,
    typing: true,
  },
  {
    id: 2,
    name: 'Jourdan Dunn',
    message: 'Hey how’s the outfit coming along?',
    avatar: 'path_to_jourdan_avatar.jpg',
    time: '10:21 am',
    unread: true,
    typing: false,
  },
  {
    id: 3,
    name: 'Fashion Girls',
    message: 'Jourdan: What you girls wearing tonight?',
    avatar: 'path_to_fashion_girls_avatar.jpg',
    time: 'Yesterday',
    unread: true,
    typing: false,
  },
];

const ChatList = () => {
  return (
    <div className="chat-list-modern">
      {/* Header */}
      <div className="header">
        <h2>Conversations</h2>
        <div className="icons">
          <button className="icon new-message">+</button>
          <button className="icon chat">💬</button>
          <button className="icon profile">👤</button>
        </div>
      </div>

      {/* Chat Items */}
      <div className="chat-items">
        {chats.map(chat => (
          <div className="chat-item" key={chat.id}>
            <div className="avatar-container">
              <img src={chat.avatar} alt={chat.name} className="avatar" />
              {chat.unread && <span className="unread-badge">3</span>}
            </div>
            <div className="chat-info">
              <div className="chat-header">
                <span className="chat-name">{chat.name}</span>
                <span className="chat-time">{chat.time}</span>
              </div>
              <div className="chat-message">
                {chat.typing ? <em>Typing...</em> : chat.message}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChatList;
