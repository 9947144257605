import React, { useContext, useEffect, useState } from "react";
import { ConnectorContext } from '../App';

// Ringer Component
const RingerComponent = () => {
  //const [isRinging, setIsRinging] = useState(false);
  const { requester, setCurrentComponent, activeComponent, addMediaStream, audio, contacts, setIsRinging, handleDecline } = useContext(ConnectorContext);
  //const audio = new Audio("../assets/the_ecstasy_of_gold.mp3"); // Specify the path to your ringtone file
  //const [audio, setAudio] = useState(null);
  const [hasUserInteracted, setHasUserInteracted] = useState(false); // Track user interaction


  // Check if permission was previously granted
  useEffect(() => {
    const storedPermission = localStorage.getItem("autoplayPermission");
    if (storedPermission === "granted") {
      setHasUserInteracted(true);
      audio.current = new Audio(`${process.env.PUBLIC_URL}/assets/the_ecstasy_of_gold.mp3`); // Load ringtone automatically
    }
  }, []);

  useEffect(() => {
    if (hasUserInteracted) {
      // Play ringtone if the user has previously granted permission
      audio.current.loop = true;
      audio.current.play().catch((error) => console.log("Failed to play audio:", error));
      setIsRinging(true);
    } else {
      setIsRinging(false);
      if (audio.current) audio.current.pause();
    }

    //console.log(audio)

    //return () => {
      //audio.pause(); // Clean up on component unmount or when the call ends
    //};
  }, [hasUserInteracted, audio]);

  // Handle initial user interaction to allow audio play
  const handleUserInteraction = () => {
    setHasUserInteracted(true);
    const ringtone = new Audio(`${process.env.PUBLIC_URL}/assets/the_ecstasy_of_gold.mp3`); // Load ringtone after interaction
    audio.current = ringtone;

    // Save permission status in localStorage for future visits
    localStorage.setItem("autoplayPermission", "granted");
  };

  const handleAnswer = async() => {
    audio.current.pause();   // Stop ringtone
    setIsRinging(false);
    activeComponent.current = 'videoCall'
    setCurrentComponent('videoCall')
    await addMediaStream(requester)
    //onAnswer();      // Call the function to answer the call
  };

  const handleAudioAnswer = async() => {
    audio.current.pause();   // Stop ringtone
    setIsRinging(false);
    activeComponent.current = 'videoCall'
    setCurrentComponent('videoCall')
    await addMediaStream(requester, "audioOnly")
    //onAnswer();      // Call the function to answer the call
  };

  return (
    <div className="audio-call-container">
        {!hasUserInteracted && (
            <button onClick={handleUserInteraction}>
            Enable Call Notifications
            </button>
        )}
        <div className="top-controls">
            <div style={{width: '80%'}}>
              <h3 style={{margin: 0, padding: 0, textAlign: 'center'}}>Incoming Call</h3>
              <h5 style={{margin: 0, padding: 0, textAlign: 'center'}}>{contacts.filter(elem=>elem.email === requester)[0].name} is calling . . .</h5>
            </div>
        </div>

        <img src={`${process.env.PUBLIC_URL}/assets/contact-placeholder.png`} alt="image" className="profile-img-main" />

        <div className="call-controls" style={{width: '50%', justifyContent: 'space-between', backgroundColor:'#f5f5f5'}}>
            <button id="end-call-button" onClick={() => handleDecline(requester)}><i className="bi bi-telephone" style={{ fontSize: '20px', transform: 'rotate(90deg)' }}></i></button>
            <button id="accept-call-button" onClick={handleAudioAnswer}><i className="bi bi-telephone" style={{ fontSize: '20px' }}></i></button>
            <button id="accept-call-button" onClick={handleAnswer}><i className="bi bi-camera-video" style={{ fontSize: '20px' }}></i></button>
        </div>
    </div>
  );
};

export default RingerComponent;
