import React, { useContext, useRef, useState, useEffect } from "react";
import Draggable from "react-draggable";
import { ConnectorContext } from '../App';
import './ScreenShare.css'; // For custom styling
import DraggableVideo from "./DraggableVideo";

const RemoteView = () => {
    const { user, peers, setPeers, currentPeers, sharedScreenStream, startScreenShare, stopScreenShare, selectedContact, setSelectedContact, requester, setRequester, setCurrentComponent, createPeerConnection, fullScreenVideoRef, floatingVideoRef, localStream, remoteStream, activeComponent, endAudioCall, localAudioRef, remoteAudioRef, addVideo, setAddVideo, addVideoRequest, setAddVideoRequest, addMediaStream, endCall } = useContext(ConnectorContext);
  
    const sharedScreenRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const localVideoRef = useRef(null);

  const [isLocalFullScreen, setIsLocalFullScreen] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isCameraOff, setIsCameraOff] = useState(false);
  const [isFrontCamera, setIsFrontCamera] = useState(true);

  // Attach streams to video elements
  useEffect(() => {
    console.log(sharedScreenStream, remoteStream, localStream)
    console.log(sharedScreenRef.current, remoteVideoRef.current, localVideoRef.current)
    if (sharedScreenStream && sharedScreenRef.current) {
      sharedScreenRef.current.srcObject = sharedScreenStream;
    }
    if (remoteStream && remoteVideoRef.current) {
      remoteVideoRef.current.srcObject = remoteStream;
    }
    if (localStream && localVideoRef.current) {
      localVideoRef.current.srcObject = localStream;
    }
  }, [sharedScreenStream, remoteStream, localStream]);

  const handleEndCall = () => {
    let activeCaller = null

    if (selectedContact) {
        activeCaller = selectedContact.email
    } else if (requester) {
        activeCaller = requester
    }
    currentPeers.current[activeCaller].signalDataChannel.send(JSON.stringify({type: "endCall", contact: user.email}));
    endCall(activeCaller)
};

// Mute/Unmute the local audio stream
const toggleMute = () => {
    const audioTrack = localStream.getAudioTracks()[0];
    if (audioTrack) {
    audioTrack.enabled = !audioTrack.enabled;
    setIsMuted(!audioTrack.enabled);
    }
};

// Turn camera on/off
const toggleCamera = () => {
    const videoTrack = localStream.getVideoTracks()[0];
    if (videoTrack) {
    videoTrack.enabled = !videoTrack.enabled;
    setIsCameraOff(!videoTrack.enabled);
    }
};

// Switch camera (for devices with multiple cameras)
const switchCamera = async () => {
    const videoTrack = localStream.getVideoTracks()[0];
    const currentDeviceId = videoTrack.getSettings().deviceId;

    videoTrack.stop();

    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter((device) => device.kind === "videoinput");

    const nextCameraDevice = videoDevices.find(
        (device) => device.deviceId !== currentDeviceId
    ) || videoDevices[0];

    const newStream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: nextCameraDevice.deviceId },
        audio: true,
    });

    const newVideoTrack = newStream.getVideoTracks()[0];
    localStream.removeTrack(videoTrack);
    localStream.addTrack(newVideoTrack);

    let activeCaller = null

    if (selectedContact) {
        activeCaller = selectedContact.email
    } else if (requester) {
        activeCaller = requester
    }
    
    const sender = currentPeers.current[activeCaller].connection.getSenders().find(s => s.track.kind === 'video');
    if (sender) {
        sender.replaceTrack(newVideoTrack);
    }

    if (isLocalFullScreen) {
        fullScreenVideoRef.current.srcObject = localStream;
    } else {
        floatingVideoRef.current.srcObject = localStream;
    }

    setIsFrontCamera(
    nextCameraDevice.label.toLowerCase().includes("front")
    );
};

  const shareScreen = async() => {
    let activeCaller = null

    if (selectedContact) {
        activeCaller = selectedContact.email
    } else if (requester) {
        activeCaller = requester
    }
    startScreenShare(activeCaller)
    //activeComponent.current = 'shareScreen'
    //setCurrentComponent('shareScreen')
    //currentPeers.current[contact.email].signalDataChannel.send(JSON.stringify({type: "remote", contact: user.email}));
  };

  return (
    <div className="remote-view-container">
      {/* Fullscreen shared screen */}
      <div className="fullscreen-shared-screen">
        <video
          ref={sharedScreenRef}
          autoPlay
          playsInline
          style={{ width: "100%", height: "100%", margin: 0, padding: 0 }}
        />
      </div>

      {/* Local video draggable */}
      {localStream && <DraggableVideo stream={localStream} />}

      {/* Remote video draggable */}
      {remoteStream && <DraggableVideo stream={remoteStream} />}

      {/* Controls for mute, camera, and switch camera */}
      <div className="call-controls">
        <button onClick={toggleCamera}>
            {isCameraOff ? (<i className="bi bi-camera-video" style={{ fontSize: '20px' }}></i>) : (<i className="bi bi-camera-video-off" style={{ fontSize: '20px' }}></i>)}
        </button>
        <button onClick={shareScreen}><i className="bi bi-display" style={{ fontSize: '20px' }}></i></button>
        <button onClick={switchCamera}>
            {isFrontCamera ? (<i className="bi bi-camera2" style={{ fontSize: '20px' }}></i>) : (<i className="bi bi-webcam" style={{ fontSize: '20px' }}></i>)}
        </button>
        <button onClick={toggleMute}>
            {isMuted ? (<i className="bi bi-mic" style={{ fontSize: '20px' }}></i>) : (<i className="bi bi-mic-mute" style={{ fontSize: '20px' }}></i>)}
        </button>
        <button id="end-call-button" onClick={handleEndCall}><i className="bi bi-telephone" style={{ fontSize: '20px' }}></i></button>
      </div>
    </div>
  );
};

export default RemoteView;
