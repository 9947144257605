import React, { useContext, useState, useEffect } from "react";
import { ConnectorContext } from '../App';

const FileTransfer = () => {
    const { contacts, peers, incomingFile, incomingProgress, requester, selectedContact, incomingStatus, incomingSpeed, chatMessages, setChatMessages, currentChatMessages, setCurrentComponent, activeComponent, currentSelectedContact, updateIncomingConnections, startScreenShare, connectionOpen, activeVideoCall, setActiveVideoCall, activeAudioCall, setActiveAudioCall, file, setFile, progress, setProgress, status, setStatus, speed, setSpeed } = useContext(ConnectorContext);

  // State for outgoing files
  //const [file, setFile] = useState(null);
  //const [progress, setProgress] = useState(0);
  //const [status, setStatus] = useState(null);
  //const [speed, setSpeed] = useState(0);

  // State for incoming files
  //const [incomingFile, setIncomingFile] = useState(null);
  //const [incomingProgress, setIncomingProgress] = useState(0);
  //const [incomingSpeed, setIncomingSpeed] = useState(0);
  //const [incomingStatus, setIncomingStatus] = useState("");

  // Handler for selecting outgoing files
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setStatus("");
      setProgress(0);
      setSpeed(0);
    }
  };

    const startFileTransfer = () => {
        if (!file) {
          alert("Please select a file to send!");
          return;
        }
      
        setStatus("Sending...");
        setProgress(0);
      
        const totalFileSize = file.size; // File size in bytes
        let uploadedBytes = 0;
        let offset = 0;
        let isPaused = false;

        let lastUploadedBytes = 0; // To calculate instantaneous speed
        let lastTime = Date.now(); // To track time intervals for speed calculation

      
        const dataChannel = peers[requester ? requester : selectedContact.email]?.fileDataChannel;
        if (dataChannel && dataChannel.readyState === 'open') {
          const chunkSize = 16 * 1024; // 16KB chunks
          const reader = new FileReader();
          const bufferThreshold = 64 * 1024; // 64KB
      
          // Inform the receiver about the incoming file
          dataChannel.send(
            JSON.stringify({
              type: "start",
              fileName: file.name,
              fileSize: file.size,
            })
          );
      
          // Function to read the file in chunks
          const readSlice = (o) => {
            const slice = file.slice(o, o + chunkSize);
            reader.readAsArrayBuffer(slice);
          };
      
          reader.onload = (e) => {
            const chunk = e.target.result;
      
            // Check if we should pause the transfer
            if (dataChannel.bufferedAmount + chunk.byteLength >= bufferThreshold) {
              isPaused = true;
            } else {
              // Send the chunk and update offset and progress
                dataChannel.send(chunk);
                offset += chunk.byteLength;
                uploadedBytes += chunk.byteLength;

                // Calculate progress
                const newProgress = (uploadedBytes / totalFileSize) * 100;
                setProgress(newProgress);

                // Calculate instantaneous speed
                const now = Date.now();
                const timeElapsed = (now - lastTime) / 1000; // Time in seconds since last calculation
                if (timeElapsed >= 1) { // Update speed every second
                const bytesSinceLast = uploadedBytes - lastUploadedBytes;
                const newSpeed = bytesSinceLast / timeElapsed; // Bytes per second
                
                setSpeed(newSpeed); // Update speed state
                
                // Update tracking variables for the next interval
                lastUploadedBytes = uploadedBytes;
                lastTime = now;
                }
      
              if (offset < totalFileSize) {
                readSlice(offset);
              } else {
                dataChannel.send(JSON.stringify({ type: "end" }));
                setStatus("Transfer Complete");
              }
            }
          };
      
          reader.onerror = (error) => {
            console.error("FileReader error:", error);
            setStatus("Error during transfer");
          };
      
          // Function to manage sending based on buffer status
          const manageBuffer = () => {
            if (isPaused && dataChannel.bufferedAmount < bufferThreshold) {
              isPaused = false;
              readSlice(offset); // Resume transfer from where it left off
            }
          };
      
          // Check buffer status periodically
          const bufferCheckInterval = setInterval(manageBuffer, 100); // Check every 100ms
      
          // Stop checking when the transfer is complete
          const stopBufferCheck = () => clearInterval(bufferCheckInterval);
      
          // Start reading from the file
          readSlice(0);
          
          // Clear interval when transfer completes
          dataChannel.addEventListener('close', stopBufferCheck);
        } else {
          alert("Data channel is not open.");
          setStatus("Data channel not open.");
        }
    };
      
  // Helper to format bytes as KB/MB/GB
  const formatBytes = (bytes) => {
    if (bytes === 0) return "0 B";
    const k = 1024;
    const sizes = ["B", "KB", "MB", "GB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    var j = i
    if (i < 0) {
        j = i * -1
    }
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[j];
  };

    const showContacts = () => {
        activeComponent.current = 'contacts'
        setCurrentComponent('contacts')
    };

  return (
    <div style={styles.container}>
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", width: "80%"}}>
            <span onClick={showContacts} style={{ margin: "20px" }}><i className="bi bi-caret-left" style={{ color: "#fdb271", fontSize: "28px" }}></i></span>
            <h2>Instant File Transfer</h2>
            <span></span>
        </div>

      <h3 style={{margin: "5px 0"}}>{selectedContact ? selectedContact.name : contacts.filter(elem=>elem.email === requester)[0].name}</h3>

      {/* Outgoing File Transfer */}
      <div style={styles.transferSection}>
        <h3 style={{margin: "5px 0"}}>Outgoing File</h3>
        <input type="file" onChange={handleFileChange} style={styles.input} />

        {file && (
          <div style={styles.fileDetails}>
            <p style={{margin: "5px 0"}}>File: {file.name}</p>
            <p>Size: {formatBytes(file.size)}</p>
          </div>
        )}

        {connectionOpen && status == '' && <span onClick={startFileTransfer} style={styles.button}>
            Send
        </span>}

        {status && <p style={{margin: "5px 0"}}>{status}</p>}

        <div style={styles.progressBar}>
          <div
            style={{
              ...styles.progressFill,
              width: `${progress}%`,
            }}
          ></div>
        </div>

        {progress > 0 && (
          <div style={styles.transferInfo}>
            <p style={{margin: "5px 0"}}>{Math.round(progress)}% complete</p>
            <p style={{margin: "5px 0"}}>Speed: {formatBytes(speed)}/s</p>
          </div>
        )}
      </div>

      {/* Incoming File Transfer */}
      <div style={styles.transferSection}>
        <h3>Incoming File</h3>

        {incomingFile && (
          <div style={styles.fileDetails}>
            <p style={{margin: "5px 0"}}>File: {incomingFile.name}</p>
            <p style={{margin: "5px 0"}}>Size: {formatBytes(incomingFile.size)}</p>
          </div>
        )}

        {incomingStatus && <p style={{margin: "5px 0"}}>{incomingStatus}</p>}

        <div style={styles.progressBar}>
          <div
            style={{
              ...styles.progressFill,
              width: `${incomingProgress}%`,
            }}
          ></div>
        </div>

        {incomingProgress > 0 && (
          <div style={styles.transferInfo}>
            <p style={{margin: "5px 0"}}>{Math.round(incomingProgress)}% complete</p>
            <p style={{margin: "5px 0"}}>Speed: {formatBytes(incomingSpeed)}/s</p>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: "100%",
    margin: 0,
    textAlign: "center",
    padding: 0,
  },
  transferSection: {
    marginBottom: "30px",
  },
  input: {
    margin: "10px 0",
  },
  fileDetails: {
    marginBottom: "10px",
  },
  button: {
    padding: "10px 15px",
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    marginBottom: "10px",
  },
  progressBar: {
    width: "80%",
    height: "10px",
    backgroundColor: "#f3f3f3",
    borderRadius: "10px",
    overflow: "hidden",
    marginTop: "20px",
    marginLeft: "10%"
  },
  progressFill: {
    height: "100%",
    backgroundColor: "#4CAF50",
    transition: "width 0.5s",
  },
  transferInfo: {
    marginTop: "10px",
  },
};

export default FileTransfer;
