// referenceStore.js

const referenceStore = { "allPeers" : {}, "allChatMessages" : {}, "selectedContact" : {}, "currentUser" : {}, "contacts" : [] };

export const setReference = (key, value) => {
    referenceStore[key] = value;
};

export const getReference = (key) => {
    return referenceStore[key];
};

export const removeReference = (key) => {
    delete referenceStore[key];
};

// Push an element to an array stored in the reference store
export const pushToICECandidates = (key, id, element) => {
    console.log(key, id, element, referenceStore)
    referenceStore[key][`${id}`].localICECandidates.push(element);
};

// Expose the reference store to the global window object for console access
window.referenceStore = referenceStore;
window.pushToICECandidates = pushToICECandidates;
window.getReference = getReference;