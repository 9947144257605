// globalSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    peersKey: "allPeers",
    chatMessages: {},
    chatMessagesKey: "allChatMessages",
    input: {},
    contactsKey: "contacts",
    contacts: [],
    connectionsKey: null,
    currentUserKey: "currentUser",
    selectedContactKey: "selectedContact",
    selectedContact: {},
};

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setPeersKey: (state, action) => {
            state.peersKey = action.payload;
        },
        setChatMessages: (state, action) => {
            state.chatMessages = action.payload;
        },
        setChatMessagesKey: (state, action) => {
            state.chatMessagesKey = action.payload;
        },
        setInput: (state, action) => {
            state.input = action.payload;
        },
        setContactsKey: (state, action) => {
            state.contactsKey = action.payload;
        },
        setContacts: (state, action) => {
            state.contacts = action.payload;
        },
        setConnectionsKey: (state, action) => {
            state.connectionsKey = action.payload;
        },
        setCurrentUserKey: (state, action) => {
            state.currentUserKey = action.payload;
        },
        setSelectedContactKey: (state, action) => {
            state.selectedContactKey = action.payload;
        },
        setSelectedContact: (state, action) => {
            state.selectedContact = action.payload;
        },
        clearGlobalVarKey: (state) => {
            state.peersKey = "allPeers"
            state.chatMessages = {}
            state.chatMessagesKey = "allChatMessages"
            state.input = {}
            state.contactsKey = "contacts"
            state.contacts = []
            state.connectionsKey = null
            state.currentUserKey = "currentUser"
            state.selectedContactKey = "selectedContact"
            state.selectedContact = {}
        },
    },
});

export const { setPeersKey, setChatMessages, setChatMessagesKey, setInput, setContactsKey, setContacts, setConnectionsKey, setCurrentUserKey, setSelectedContactKey, setSelectedContact, clearGlobalVarKey } = globalSlice.actions;
export default globalSlice.reducer;